import { Fragment } from 'react'
import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import camelCase from 'lodash/camelCase'
import HeroBanner from '../../../../../../organisms/hero/hero-banner'
import VideoHeroBackground from '../../../../../../organisms/hero/video-hero-background'
import {
	default as ImageArtDirection,
	ImageBackgroundArtDirection,
	VideoBackgroundArtDirection,
} from '../../../../../../particles/art-direction'

const Banner = ({
	aboveTheFold,
	contentType,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	subtitle,
	subtitleTag,
	subtitleColor,
	subtitleColorMobile,
	ctas,
	content,
	content2,
	horizontalAlign,
	image,
	imageConfig,
	video,
	videoConfig,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
	sx,
	cssProp,
	HeroComponent,
	onClick,
	additionalContent,
	className,
	RichContentComponent,
	noParallax,
	overlayContainerProps,
}) => {
	const links = useLinks([
		{ type: ctas?.[0]?.linkType, href: ctas?.[0]?.link },
		{ type: ctas?.[1]?.linkType, href: ctas?.[1]?.link },
		{ type: ctas?.[2]?.linkType, href: ctas?.[2]?.link },
	])

	const { promotionClick } = useTrackingsActions()

	const { siteName } = useConfig()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	const handleClick = (e) => {
		if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
			trackClickEvent({
				event: e,
				id: seoBannerId,
				name: seoBannerName,
				creative: seoBannerCreative,
				position: seoBannerPosition,
			})
		}
		if (typeof onClick === 'function') onClick(e)
	}

	const hasImage = !!image?.mobile?.src
	const hasVideo = !!video?.mobile?.url

	if (!hasImage && !hasVideo) {
		console.warn(`[RichContent] PageBuilder "${contentType}" has no image or video`)
	}

	const BackgroundComponent = hasVideo
		? aboveTheFold
			? VideoBackgroundArtDirection
			: VideoHeroBackground
		: aboveTheFold
		? ImageBackgroundArtDirection
		: ImageArtDirection

	return (
		<HeroComponent
			hasVideo={hasVideo}
			aboveTheFold={aboveTheFold}
			sx={{ ...sx, minHeight, height: aboveTheFold ? '100%' : undefined }}
			css={cssProp}
			className={cx(`Magento-PageBuilder-${camelCase(contentType)}-Banner`, className)}
			backgroundComponent={
				<>
					{(hasImage || hasVideo) && (
						<BackgroundComponent
							aboveTheFold={aboveTheFold}
							alt={title ? title + ' | ' + siteName : siteName}
							{...(hasVideo ? videoConfig : {})}
							sources={{
								xs: hasVideo ? { ...video.mobile, light: false } : image.mobile,
								md: hasVideo ? { ...video.desktop, light: false } : image.desktop,
							}}
							style={{
								width: aboveTheFold ? '100%' : undefined,
								height: aboveTheFold ? '100%' : undefined,
							}}
							{...(hasImage ? imageConfig : {})}
						/>
					)}
				</>
			}
			cta={ctas?.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				url: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
			description={
				content || content2 ? (
					<>
						{content && RichContentComponent && <RichContentComponent content={content} />}
						{content2 && RichContentComponent && <RichContentComponent content={content2} />}
					</>
				) : null
			}
			horizontalAlign={horizontalAlign}
			subtitleProps={
				subtitle ? { component: subtitleTag, sx: { color: { xs: subtitleColorMobile, md: subtitleColor } } } : undefined
			}
			subtitle={subtitle}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			title={title && RichContentComponent ? <RichContentComponent content={title} component={Fragment} /> : null}
			verticalAlign="center"
			onClick={ctas?.length === 1 ? handleClick : undefined}
			additionalContent={additionalContent}
			noParallax={noParallax}
			overlayContainerProps={overlayContainerProps}
		/>
	)
}

Banner.defaultProps = { HeroComponent: HeroBanner }

export default Banner
